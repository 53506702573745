<template>
  <section class="integration">
    <Integration />
  </section>
</template>

<script>
import Integration from '@/components/Integration.vue'

export default {
  name: 'IntegrationView',
  components: {
    Integration
  }
}
</script>

