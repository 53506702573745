<template>
  <section class="brands">
    <Brands />
  </section>
</template>

<script>
import Brands from '@/components/Brands.vue'

export default {
  name: 'BrandsView',
  components: {
    Brands
  }
}
</script>

