<template>
 <input type="button" value="New Brand" @click="newBrand()" class="newbrand">
  <br>
  <table>
    <tr>
      <th>
        Bild
      </th>
      <th>
        Name
      </th>
      <th>
        Slogan 
      </th>
      <th>
        Pinned 
      </th>
      <th>
        Löschen 
      </th>
    </tr>
    <tr>
     <th>
     </th>
      <th>
        <input id="search_name" type="text" @input.prevent.stop="search($event.target.value)" v-model="search_name" />
      </th>
     <th>
        <input id="search_slogan" type="text" @input.prevent.stop="search($event.target.value)" v-model="search_slogan" />
      </th>
      <th>
        <select id="search_pinned" @change.prevent.stop="search($event.target.value)" v-model="search_pinned">
          <option value="both">both</option>
          <option value="yes">yes</option>
          <option value="no">no</option>
        </select>
      </th>
      <th></th>
    </tr>

    <tr v-for="brand in brands" :key="brand.id">
      <td>
      <input :id="brand.id + '_upload'" type="file" name="fileUpload" accept=".jpg" @change.prevent.stop="uploadImage(brand.id,$event)">
      <label :for="brand.id + '_upload'">
       <img :src="getImage(brand)">
      </label>
      </td>
      <td>
        <input type="text" :value="brand.name" @input.prevent.stop="updateName(brand.id, $event.target.value)" class="name"/>
      </td>
     <td>
        <input type="text" :value="brand.slogan" @input.prevent.stop="updateSlogan(brand.id, $event.target.value)" class="slogan"/>
      </td>
      <td>
        <input type="checkbox" :checked="brand.pin" @change.prevent.stop="togglePinned(brand.id)" />
      </td>
     <td>
        <input type="button" value="Delete" @click="deleteBrand(brand)" class="deletebrand">
     </td>
    </tr>
  </table>
</template>

<script>
import store from '@/store'
import debounce from "lodash.debounce"

const debounce_time = 1000

export default {
  name: 'Brands',
  data() {
    return {
      refreshKey: 0,
      search_name: "",
      search_slogan: "",
      search_pinned: "both"
    }
  },
  methods: {
 
    togglePinned(brand_id) {
      window.socket.send(JSON.stringify(["toggle pinned", brand_id]))
    },
    deleteBrand(brand) {
      if(confirm("Delete brand: "+brand.name+ "?") == true )
         window.socket.send(JSON.stringify(["delete brand", brand.id]))
    },
   updateName: debounce( (brand_id, name) => {
      window.socket.send(JSON.stringify(["update brand", brand_id, {name: name}]))
    }, debounce_time),
   updateSlogan: debounce( (brand_id, slogan) => {
      window.socket.send(JSON.stringify(["update brand", brand_id, {slogan: slogan}]))
    }, debounce_time),

   newBrand() {
      window.socket.send(JSON.stringify(["insert brand"]))
    }, 
    getImage(brand) {
       if(brand.logo == null) brand.logo = "dummy.jpg";
       return process.env.VUE_APP_API_URL + "/static/" + brand.logo;
    },
    uploadImage(brand_id,e){
      var files = e.target.files || e.dataTransfer.files;
      var reader = new FileReader();
      reader.addEventListener("loadend", async function() {
           window.socket.send(JSON.stringify(["set brand image", brand_id, this.result]))
      })

      reader.readAsDataURL(files[0]);
    },
    search(e){
       this.refreshKey++;
       console.log(e)
    }
  },
  computed: {
    brands() {
      this.refreshKey;
      let brands = store.state.brands
    
      let name = ""
      name = this.search_name  
      let slogan = ""
      slogan = this.search_slogan
      let pinned = "both"
      pinned = this.search_pinned


      brands = brands.filter(function(brand){
          let show = true 

          if(show && (name == "" || brand.name.toUpperCase().match(name.toUpperCase()))) show = true
            else show = false

          if(show && (slogan == "" || (brand.slogan && brand.slogan.toUpperCase().match(slogan.toUpperCase())))) show = true 
            else show = false

         if(show && (pinned == "both" || (pinned == "yes" && brand.pin == true) || (pinned == "no" && brand.pin == false) )) show = true
            else show = false

          return show;
        })
      return brands 
    }
  }
}
</script>

