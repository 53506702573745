<template>
  
  <div>
    <h2>Uber</h2>
    <table>
      <tr>
        <th>
          Brand
        </th>
        <th>
          Uber Store ID
        </th>
        <th>
          <button @click="pushAll()">Push All</button>
        </th>
      </tr>
      <tr>
        <th>Food Court</th>
        <td>
          <input :value="value(integrations['uber'], null, 'uber_store_id')" @input.prevent.stop="updateUber(null, $event.target.value)" />
        </td>
        <td>
          <button @click="push(null)">Push</button>
        </td>
      </tr>
      <tr v-for="brand in brands" :key="brand['id']">
        <th>{{brand['name']}}</th>
        <td>
          <input :value="value(integrations['uber'], brand['id'], 'uber_store_id')" @input.prevent.stop="updateUber(brand['id'], $event.target.value)" />
        </td>
        <td>
          <button @click="push(brand['id'])">Push</button>
        </td>
      </tr>
    </table>
  </div>
  
  <div>
    <h2>Wolt</h2>
    <table>
      <tr>
        <th>Brand</th>
        <th>Wolt Venue ID</th>
        <th>Wolt API Key</th>
      </tr>
      <tr>
        <th>Food Court</th>
        <td>
          <input :value="value(integrations['wolt'], null, 'wolt_venue_id')" @input.prevent.stop="updateWoltVenueId(null, $event.target.value)" />
        </td>
        <td>
          <input :value="value(integrations['wolt'], null, 'wolt_api_key')" @input.prevent.stop="updateWoltApiKey(null, $event.target.value)" />
        </td>
      </tr>
      <tr v-for="brand in brands" :key="brand['id']">
        <th>{{brand['name']}}</th>
        <td>
          <input :value="value(integrations['wolt'], brand['id'], 'wolt_venue_id')" @input.prevent.stop="updateWoltVenueId(brand['id'], $event.target.value)" />
        </td>
        <td>
          <input :value="value(integrations['wolt'], brand['id'], 'wolt_api_key')" @input.prevent.stop="updateWoltApiKey(brand['id'], $event.target.value)" />
        </td>
      </tr>
    </table>
  </div>
  
  <div>
    <h2>Lieferando</h2>
    <table>
      <tr>
        <th>Brand</th>
        <th>Lieferando Store ID</th>
      </tr>
      <tr>
        <th>Food Court</th>
        <td>
          <input :value="value(integrations['lieferando'], null, 'lieferando_restaurant_id')" @input.prevent.stop="updateLieferando(null, $event.target.value)" />
        </td>
      </tr>
      <tr v-for="brand in brands" :key="brand['id']">
        <th>{{brand['name']}}</th>
        <td>
          <input :value="value(integrations['lieferando'], brand['id'], 'lieferando_restaurant_id')" @input.prevent.stop="updateLieferando(brand['id'], $event.target.value)" />
        </td>
      </tr>
    </table>
  </div>

</template>

<script>
import store from '@/store'
import debounce from "lodash.debounce"
const debounce_time = 1000

// TODO: update debounce function so it doesn't overwrite when quickly changing multiple fields

export default {
  name: 'Integration',
  created: function () {
  },
  methods: {
    updateUber: debounce( (brand_id, uber_store_id) => {
      window.socket.send(JSON.stringify(["integration update uber", brand_id, uber_store_id]))
    }, debounce_time),
    updateWoltVenueId: debounce( (brand_id, wolt_venue_id) => {
      window.socket.send(JSON.stringify(["integration update wolt venue id", brand_id, wolt_venue_id]))
    }, debounce_time),
    updateWoltApiKey: debounce( (brand_id, wolt_api_key) => {
      window.socket.send(JSON.stringify(["integration update wolt api key", brand_id, wolt_api_key]))
    }, debounce_time),
    updateLieferando: debounce( (brand_id, lieferando_restaurant_id) => {
      window.socket.send(JSON.stringify(["integration update lieferando", brand_id, lieferando_restaurant_id]))
    }, debounce_time),
    value(platformIntegrations, brand_id, column) {
      // I really wish there was an expressive language
      // feature for this one
      if (brand_id in platformIntegrations)
        return platformIntegrations[brand_id][column]
      return null
    },
    push(brand_id) {
      window.socket.send(JSON.stringify(["integration push", brand_id]))
    },
    pushAll() {
      window.socket.send(JSON.stringify(["integration push all"]))
    }
  },
  computed: {
    integrations() {
      return store.state.integrations
    },
    brands() {
      return store.state.brands
    },
    columns() {
      return {
        'uber': ['uber_store_id'],
        'wolt': ['wolt_venue_id', 'wolt_api_key'],
        'lieferando': ['lieferando_restaurant_id'],
      }
    }
  }
}
</script>


